
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {
		// All pages
		'common': {
			init: function() {
        // JavaScript to be fired on all pages
        
        //Hamburger
				$('.hamburger').on('click', function() {
					$(this).toggleClass('is-active');
					if ($(this).hasClass('is-active')) {
						$('.topnav').slideDown(500);
						$('.header').addClass('menu-open');
					} else {
						$('.topnav').slideUp(500);
						$('.header').removeClass('menu-open');
					}
				});
				$(window).resize(function(){
					$('.topnav').attr('style', '');
					$('.hamburger').removeClass('is-active');
					$('.header').removeClass('menu-open');
				});

				// Scroll To
				(function() {
					$('.scrollto').on('click', function(e){
						e.preventDefault();
						var section = $(this).attr('href');
						$('html, body').animate({
              scrollTop: $(section).offset().top
            }, 1000);
					});
				})();

				// Form
				(function() {
					$('select').selectric();
				})();

				// Swiper
				var mySwiper = new Swiper ('.swiper-container', {
					// Optional parameters
					direction: 'horizontal',

					pagination: {
						el: '.swiper-pagination',
						clickable: 'true',
					},
					fadeEffect: {
            crossFade: true
					},
					loop: true
				});

				//Waypoints
				if ($('.reveal').length > 0){
          $('.reveal').each(function(e){
            var reveal = $(this);
            var waypoint_reveal = new Waypoint({
              element: reveal[0],
              handler: function(direction) {
                if (direction === 'down'){
                  reveal.addClass('on');
                }
              },
              offset: '95%'
            });
          });
        }

        //Header Code
        // Hide Header on on scroll down
				var didScroll;
				var lastScrollTop = 0;
				var delta = 5;
				var navbarHeight = $('header').outerHeight();

				$(window).scroll(function(event){
          didScroll = true;
				});

				setInterval(function() {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
				}, 250);

				function hasScrolled() {
          var st = $(window).scrollTop();
          
          // Make sure they scroll more than delta
          if(Math.abs(lastScrollTop - st) <= delta) {
            return;
          }
          
          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('header').removeClass('nav-down').addClass('nav-up');
          } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
              $('header').removeClass('nav-up').addClass('nav-down');
            }
          }
          lastScrollTop = st;
				}
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// Home page
		'page_template_template_home': {
			init: function() {

				if (window.innerWidth <= 850 || (window.innerHeight <= 750)) {
          $.scrollify.disable();
          $('.stock').addClass('visible');
          $('.custom').addClass('visible');
          $('.jansan').addClass('visible');
				}

				var sticky = new Waypoint.Sticky({
					element: $('.stock')[0]
				});

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
				$(function() {
          $.scrollify({
            section : ".scrollify",
            interstitialSection : ".intro",
            scrollSpeed: 1250,
            setHeights: false,
            offset: 0,
            before:function(i) {
              console.log('before '+ i);
              if (i === 4) {
                $('.jansan').addClass('last');
              }
            },
            after:function(i) {
              console.log('after ' + i);
              if (i === 0) {
                $('.stock').removeClass('visible');
                $('.custom').removeClass('visible');
                $('.jansan').removeClass('visible');
              }
              if (i === 1) {
                $('.stock').addClass('visible');
                $('.custom').removeClass('visible');
                $('.jansan').removeClass('visible');
              }
              if (i === 2) {
                $('.stock').removeClass('visible');
                $('.custom').addClass('visible');
                $('.jansan').removeClass('visible');
              }
              if (i === 3) {
                $('.stock').removeClass('visible');
                $('.custom').removeClass('visible');
                $('.jansan').addClass('visible');
                $('.jansan').removeClass('last');
              }
              if (i === 4) {
                $('.stock').removeClass('visible');
                $('.custom').removeClass('visible');
                $('.jansan').removeClass('visible');
              }
            },
            afterResize:function(){
              if ((window.innerWidth <= 850) || (window.innerHeight <= 750)) {
                $.scrollify.disable();
                $('.stock').addClass('visible');
                $('.custom').addClass('visible');
                $('.jansan').addClass('visible');
              } else if ($.scrollify.isDisabled()) {
                $.scrollify.enable();
              }
            },
          });
        });
			}
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.


